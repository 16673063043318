<script>
	export let title = 'Error';
</script>
<div class="box failure small">
	<div class="value">
		<div class="icon">
			<i class="fad fa-ban"></i>
		</div>
	</div>
	<div class="type">
		<i class="fad fa-ban"></i>
		<div class="label">{title}</div>
	</div>
</div>