<script>

	import BarError from './Bar/Error.svelte';
	import BarSuccess from './Bar/Success.svelte';
	import BoxError from './Box/Error.svelte';
	import BoxLoading from './Box/Loading.svelte';
	import Config from '../Config';
	import Resource from './Settings/Resource.svelte';
	import Source from './Settings/Source.svelte';
	import Route from '../Stores/Route';

	async function resourcesRequest() {
		const response = await fetch(`${Config.settings.endpoint}/resource`);
		return await response.json();
	}

	async function sourcesRequest() {
		const response = await fetch(`${Config.settings.endpoint}/source`);
		return await response.json();
	}

	let success = $Route.data.success || '';
	let error = $Route.data.error || '';
	let resources = resourcesRequest();
	let sources = sourcesRequest();

</script>

<h1>Settings</h1>

{#if success}
	<BarSuccess title="{success}"/>
{/if}

{#if error}
	<BarError title="{error}"/>
{/if}

<h2><i class="fad fa-sensor-alert"></i> Sensors</h2>
{#await resources}
	<BoxLoading/>
{:then resources}
	{#each resources as resource}
		<div class="box small click" on:click="{(event) => Route.to(Resource, {id: resource.id})}">
			<div class="value">
				<div class="icon">
					<i class="{resource.icon}"></i>
				</div>
			</div>
			<div class="type">
				<i class="fad fa-sensor"></i>
				<div class="label">{resource.name}</div>
			</div>
		</div>
	{/each}
{:catch error}
	<BoxError title="API Error"/>
{/await}

{#await sources}
	<BoxLoading/>
{:then sources}
	<h2><i class="fad fa-server"></i> Servers</h2>
	{#each sources.servers as server}
		<div class="box small click" on:click="{(event) => Route.to(Source, {id: server.id})}">
			<div class="value">
				<div class="icon">
					<i class="fad fa-server"></i>
				</div>
			</div>
			<div class="type">
				<div class="title">{server.name}</div>
			</div>
		</div>
	{/each}
	<h2><i class="fab fa-docker"></i> Containers</h2>
	{#each sources.dockers as docker}
		<div class="box small click {docker.mute ? 'error' : ''}" on:click="{(event) => Route.to(Source, {id: docker.id})}">
			<div class="value">
				<div class="icon">
					<i class="fab fa-docker"></i>
				</div>
			</div>
			<div class="type">
				<div class="title">{docker.name}</div>
			</div>
		</div>
	{/each}
{:catch error}
	<BoxError title="API Error"/>
{/await}