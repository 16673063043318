import Config from '../Config';
import Settings from '../Components/Settings.svelte';
import Route from '../Stores/Route';

export default class {

	static async submit(type, id) {
		try {
			const form = document.querySelector(`#${type}_form`);
			let data = new FormData(form);
			const config = {method: 'PATCH', body: data};
			const response = await fetch(`${Config.settings.endpoint}/${type}/${id}`, config);
			const json = await response.json() || {};
			Route.to(Settings, json.success ? {success: json.message} : {error: json.message});
		}

		catch (error) {
			console.error(error);
			Route.to(Settings, {error: error.message || error});
		}
	}

}