<script>

	import Config from '../../Config';
	import Error from '../Box/Error.svelte';
	import Loading from '../Box/Loading.svelte';
	import Options from './Options.svelte';
	import Route from '../../Stores/Route';
	import Settings from '../../Models/Settings';

	const id = $Route.data.id;

	let source = sourceRequest();
	let resources = resourcesRequest();
	let mute = false;

	function ignore(types, type) {
		let ignore = false;
		for (let index in types.ignores)
			if (types.ignores[index].resource.code == type) {
				ignore = true;
				break;
		}

		return ignore;
	}

	async function sourceRequest() {
		const response = await fetch(`${Config.settings.endpoint}/source/${id}`);
		let source = await response.json();
		source.fields = [
			{code: 'name', name: 'Name', icon: 'fad fa-tag', value: source.name},
		];

		mute = !!source.mute;
		return source;
	}

	async function resourcesRequest() {
		const response = await fetch(`${Config.settings.endpoint}/resource`);
		let data = await response.json();
		let types = await source;
		for (let index in data)
			data[index].ignore = ignore(types, data[index].code);

		return data;
	}

</script>

<style>
	label input,
	label span {
		vertical-align: middle;
	}

	i.color-success,
	i.color-error {
		opacity: 1;
	}

	.box.click.property > .label {
		margin-top: 1em;
	}

</style>

{#await source}
	<h1>Settings: Source</h1>
	<h2><i class="fad fa-tag"></i> Properties</h2>
	<Loading/>
{:then source}
	<h1>Settings: Source: {source.name}</h1>
	<form id="source_form">
		<h2><i class="fad fa-tag"></i> Properties</h2>
		{#each source.fields as field}
			<div class="box">
				<div class="value small">
					<div class="icon">
						<i class="{field.icon}"></i>
					</div>
				</div>
				<div class="type">
					<i class="fad fa-pencil"></i>
					<div class="label">{field.name}</div>
				</div>
				<div class="label">
					<input name="{field.code}" type="text" value="{field.value}" placeholder="{field.tip || field.name}"/>
				</div>
			</div>
		{/each}

		{#if source.type == 'docker'}
			<div class="box click property {mute ? 'negative' : 'positive'}" on:click="{(event) => { mute = !mute; }}">
				<div class="value small">
					<div class="icon">
						<i class="fad {mute ? 'fa-bell-slash' : 'fa-bell'}"></i>
					</div>
				</div>
				<div class="type">
					<i class="fad {mute ? 'fa-times-circle' : 'fa-check-circle'}"></i>
					<div class="label">{mute ? 'Muted' : 'Unmuted'}</div>
				</div>
				<div class="label">
					<label>
						<input name="mute" type="checkbox" bind:checked="{mute}" value="{mute}"/>
						<span>Mute</span>
					</label>
				</div>
			</div>
		{/if}

		{#if source.type == 'server'}
			<h2><i class="fad fa-sensor-alert"></i> Sensors</h2>
			{#await resources}
				<Loading/>
			{:then resources}
				{#each resources as resource}
					<div class="box click {resource.ignore ? 'negative' : 'positive'}" on:click="{(event) => { resource.ignore = !resource.ignore; }}">
						<div class="value small">
							<div class="icon">
								<i class="{resource.icon}"></i>
							</div>
						</div>
						<div class="type">
							<i class="fad {resource.ignore ? 'fa-times-circle' : 'fa-check-circle'}"></i>
							<div class="label">{resource.name}</div>
						</div>
						<div class="label">
							<label>
								<input name="ignore_{resource.code}" type="checkbox" bind:checked="{resource.ignore}" value="{resource.code}"/>
								<span>Ignore</span>
							</label>
						</div>
					</div>
				{/each}
			{:catch error}
				<Error title="API Error"/>
			{/await}
		{/if}

		<Options submit="{(event) => Settings.submit('source', id)}"/>
	</form>
{:catch error}
	<Error title="API Error"/>
{/await}