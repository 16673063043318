<script>
	import Config from '../Config';
	import Dashboard from './Dashboard.svelte';
	import Route from '../Stores/Route';
	import Settings from './Settings.svelte';
</script>
<div class="footer-container">
	<div class="footer">
		<div class="logo">
			<img src="../media/logo.svg" alt="{Config.organisation} {Config.application}"/>
			<div class="application">{Config.organisation} <strong>{Config.application}</strong> <span>v{Config.version}</span></div>
			<div class="menu" on:click="{event => Route.to(Dashboard)}"><i class="fad fa-lightbulb-exclamation"></i> Dashboard</div>
			<div class="menu" on:click="{event => Route.to(Settings)}"><i class="fad fa-cog"></i> Settings</div>
		</div>
	</div>
</div>