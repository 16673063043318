import Problems from '../Stores/Problems';

export default class {

	connect(endpoint) {
		try {
			this.socket = new WebSocket(endpoint);
			this.socket.addEventListener('open', event => this.ping(event));
			this.socket.addEventListener('message', event => this.message(event));
			this.socket.addEventListener('error', event => this.error(event));
			this.socket.addEventListener('close', event => {
				if (!this.destroying) {
					setTimeout(() => this.connect(endpoint), 1000 * 1);
					this.error(event);
				}
			});
		}

		catch (error) {
			this.error(error);
		}
	}

	message(event) {
		let data = typeof event.data == 'undefined' ? '{}' : event.data;
		data = JSON.parse(data) || {};
		const type = typeof data.type == 'undefined' ? '' : data.type;
		if (type && typeof this[type] == 'function') {
			this.check_stamp = Date.now();
			const content = typeof data.content == 'undefined' ? {} : data.content;
			this[type](content);
		}
		else this.error('unknown socket message type');
	}

	problem(content) {
		const problems = Array.isArray(content) ? content : [];
		if (problems.length) {
			for (let index in problems)
				if ((typeof problems[index].parent != 'undefined') && Object.keys(problems[index].parent).length) {
					problems[index].type.name = problems[index].name;
					problems[index].name = problems[index].parent.name;
				}

			Problems.set(problems);
		}
		else this.success();
	}

	ping(event) {
		if (this.ping_timeout) clearTimeout(this.ping_timeout);
		if (this.socket && (typeof this.socket.readyState != 'undefined') && (this.socket.readyState === 1))
			this.socket.send('{"type": "ping"}');
		else this.error();
	}

	pong(content) {
		this.ping_timeout = setTimeout(() => this.ping({}), 1000 * 15);
	}

	success() {
		const value = {type: 'icon', content: 'fad fa-check'};
		const type = {icon: 'fad fa-cloud', name: 'Hawk'};
		Problems.set([{name: 'No Problems', level: 'success', value, type}]);
	}

	error(error) {
		if (error) console.error(error);
		const value = {type: 'icon', content: 'fad fa-exclamation-triangle'};
		const type = {icon: 'fad fa-cloud', name: 'Hawk'};
		Problems.set([{name: 'Socket Error', level: 'failure', value, type}]);
	}

	check() {
		if (this.check_timeout) clearTimeout(this.check_timeout);
		if (!this.check_stamp || ((Date.now() - this.check_stamp) > (1000 * 30))) this.error();
		this.check_timeout = setTimeout(() => this.check({}), 1000 * 5);
	}

	destroy() {
		try {
			this.destroying = true;
			clearTimeout(this.ping_timeout);
			clearTimeout(this.check_timeout);
			this.socket.close();
		}
		catch (error) { console.error(error); }
	}

}