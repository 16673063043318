<script>

	import Config from '../../Config';
	import Error from '../Box/Error.svelte';
	import Loading from '../Box/Loading.svelte';
	import Options from './Options.svelte';
	import Route from '../../Stores/Route';
	import Settings from '../../Models/Settings';

	const id = $Route.data.id;

	function limit(resource, level, type) {
		let value = '';
		for (const monitor of resource.monitors)
			if (monitor.level.code == level) {
				value = monitor[type] || '';
				break;
			}

		return value;
	}

	async function request() {
		const response = await fetch(`${Config.settings.endpoint}/resource/${id}`);
		let resource = await response.json();

		resource.fields = [
			{code: 'name', name: 'Name', icon: 'fad fa-tag', value: resource.name},
			{code: 'unit', name: 'Unit', icon: 'fad fa-percentage', value: resource.unit || ''},
			{code: 'icon', name: 'Icon', tip: 'Font Awesome', icon: 'fad fa-icons-alt', value: resource.icon || ''},
			{code: 'every', name: 'Every', tip: 'Minutes', icon: 'fad fa-clock', value: resource.every},
		];

		resource.monitors = [
			{code: 'failure', name: 'Failure', icon: 'fad fa-ban', from: limit(resource, 'failure', 'from'), to: limit(resource, 'failure', 'to')},
			{code: 'error', name: 'Error', icon: 'fad fa-exclamation-triangle', from: limit(resource, 'error', 'from'), to: limit(resource, 'error', 'to')},
			{code: 'warning', name: 'Warning', icon: 'fad fa-exclamation-square', from: limit(resource, 'warning', 'from'), to: limit(resource, 'warning', 'to')},
			{code: 'alert', name: 'Alert', icon: 'fad fa-exclamation-circle', from: limit(resource, 'alert', 'from'), to: limit(resource, 'alert', 'to')},
			{code: 'notice', name: 'Notice', icon: 'fad fa-exclamation', from: limit(resource, 'notice', 'from'), to: limit(resource, 'notice', 'to')},
		];

		return resource;
	}

	let resource = request();

</script>

<style>

	div.box.double {
		height: 12.5em;
	}

	div.box.double input {
		display: block;
	}

	div.box.double input:first-child {
		margin-bottom: .5em;
	}

</style>

{#await resource}
	<h1>Settings: Sensors</h1>
	<h2><i class="fad fa-tag"></i> Properties</h2>
	<Loading/>
{:then resource}
	<h1>Settings: Sensors: {resource.name}</h1>
	<form id="resource_form">
		<h2><i class="fad fa-tag"></i> Properties</h2>
		{#each resource.fields as field}
			<div class="box">
				<div class="value small">
					<div class="icon">
						<i class="{field.icon}"></i>
					</div>
				</div>
				<div class="type">
					<i class="fad fa-pencil"></i>
					<div class="label">{field.name}</div>
				</div>
				<div class="label">
					<input name="{field.code}" type="text" value="{field.value}" placeholder="{field.tip || field.name}"/>
				</div>
			</div>
		{/each}
		<h2><i class="fad fa-sensor-alert"></i> Alerts</h2>
		{#each resource.monitors as monitor}
			<div class="box {monitor.code} double">
				<div class="value small">
					<div class="icon">
						<i class="{monitor.icon}"></i>
					</div>
				</div>
				<div class="type">
					<i class="fad fa-pencil"></i>
					<div class="label">{monitor.name}</div>
				</div>
				<div class="label">
					<input name="{monitor.code}_to" type="text" value="{monitor.to}" placeholder="Until"/>
					<input name="{monitor.code}_from" type="text" value="{monitor.from}" placeholder="or Above"/>
				</div>
			</div>
		{/each}
		<Options submit="{(event) => Settings.submit('resource', id)}"/>
	</form>
{:catch error}
	<Error title="API Error"/>
{/await}