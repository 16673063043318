<div class="box small">
	<div class="value">
		<div class="icon">
			<i class="fad fa-spinner fa-spin"></i>
		</div>
	</div>
	<div class="type">
		<i class="fad fa-spinner fa-spin"></i>
		<div class="label">Loading</div>
	</div>
</div>