export default {
	problems: {
		endpoint: 'wss://server.hawk.flaire.nl:51001',
	},
	settings: {
		endpoint: 'https://server.hawk.flaire.nl',
	},
	organisation: 'Flaire',
	application: 'Hawk',
	version: '2023.19.12'
};