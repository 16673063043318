<script>
	import Route from '../../Stores/Route';
	import Settings from '../Settings.svelte';
	export let submit;
</script>
<h2><i class="fad fa-paper-plane"></i> Options</h2>
<div class="box click" on:click="{(event) => Route.to(Settings)}">
	<div class="value small">
		<div class="icon">
			<i class="fad fa-arrow-left"></i>
		</div>
	</div>
	<div class="type">
		<i class="fad fa-arrow-alt-circle-left"></i>
		<div class="label">Cancel</div>
	</div>
	<div class="label">
		Cancel &amp; Back
	</div>
</div>
<div class="box click success" on:click="{submit}">
	<div class="value small">
		<div class="icon">
			<i class="fad fa-check"></i>
		</div>
	</div>
	<div class="type">
		<i class="fad fa-check-circle"></i>
		<div class="label">Save</div>
	</div>
	<div class="label">
		Save &amp; Apply
	</div>
</div>