<script>

	import {onDestroy} from 'svelte';
	import Config from '../Config';
	import Dashboard from '../Models/Dashboard';
	import Problems from '../Stores/Problems';

	const dashboard = new Dashboard();
	dashboard.connect(Config.problems.endpoint);
	dashboard.check();

	onDestroy(() => dashboard.destroy());

</script>

{#each $Problems as problem}
	<div class="box {problem.level}">
		<div class="value">
			{#if problem.value.type == 'icon'}
			<div class="icon">
				<i class="{problem.value.content}"></i>
			</div>
			{:else}
			<div class="{problem.value.type}">
				{#if problem.value.unit}
					{@html problem.value.content}<span>{problem.value.unit}</span>
				{:else}
					{@html problem.value.content}
				{/if}
			</div>
			{/if}
		</div>
		<div class="type">
			<i class="{problem.type.icon}"></i>
			<div class="label">{problem.type.name}</div>
		</div>
		<div class="label">{problem.name}</div>
	</div>
{/each}